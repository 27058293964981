import React from "react";
import {
	CognitoUserPool
} from 'amazon-cognito-identity-js';

// pegar a url atual
const url = window.location.href

// pegar a variavel de ambiente da url que vem nessa estrutura https://sandbox.chancelafacil.com.br/
// a variavel de ambiente pode ser live, sandbox, localhost:3000

const urlEnv = url.split(".")[0].split("//")[1]

// se a urlEnv for igual a sandbox, a variavel backendUrl vai ser a url de sandbox "https://api.sandbox.chancelafacil.com.br/",
// se for localhost "http://localhost:8000/"
// se for live é a url de live: "https://api.live.chancelafacil.com.br/"

// função de definição da variavel backendUrl

const getUserPoolId = () => {
    let UserPoolId = null
    if (urlEnv === 'sandbox') {
        UserPoolId = "us-east-2_AL7IzBpXx"
    } else if (urlEnv === 'live') {
        UserPoolId =  "us-east-2_xvQplrJ7x"
    } else {
        UserPoolId =  "us-east-2_AL7IzBpXx"
    }

    return UserPoolId
}

const getClientId = () => {
    let ClientId = null
    if (urlEnv === 'sandbox') {
        ClientId = "315kgvm6hsj3rteepr27drmk80"
    } else if (urlEnv === 'live') {
        ClientId =  "v8p6usvn9n109jf8akd06v2in"
    } else {
        ClientId =  "315kgvm6hsj3rteepr27drmk80"
    }

    return ClientId
}

const PoolData = {
    UserPoolId: getUserPoolId(),
    ClientId: getClientId()
}

export default new CognitoUserPool(PoolData)