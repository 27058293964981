import { Helmet } from 'react-helmet-async';
import { useState, useContext, useEffect, useCallback } from 'react';
import {useNavigate} from 'react-router-dom';
// @mui
import {
  TextField,
  Card,
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  Grid,
} from '@mui/material';
// components
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import Iconify from '../components/iconify';
import Label from '../components/label';

import { EntityContext } from '../entity';

const FinalWebhookList = ({ webhookList, handleDeleteWebhook, ...other }) => {
  return (
    <Stack direction="column" spacing={2} justifyContent="space-between">
    { webhookList.map((configuration) => (
        <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography variant="subtitle2" noWrap>
          URL:  {configuration.url}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          Headers:  {JSON.stringify(configuration.headers)}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          KEY:  {configuration.configuration_key}
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="space-between">
        <IconButton size="small" color="error" onClick={_ => handleDeleteWebhook(configuration.configuration_key)}>
          <DeleteIcon />
        </IconButton>
        </Stack>
        </Stack>
    ))}
  </Stack>
  )
}

const WebhookList = ({webhookList, handleDeleteWebhook, ...other}) => {
  return (
    <>
      { webhookList.length === 0 ? 
        <Typography noWrap>Nenhuma configuração cadastrada</Typography>
       : <FinalWebhookList webhookList={webhookList} handleDeleteWebhook={handleDeleteWebhook}/> }
    </>
      )
}

const AddWebhookCard = (props) => {
  return (
    <Stack justifyContent="space-between" mb={5}>
        <Stack direction="row" alignItems="center" justifyContent="center" useFlexGap flexWrap="wrap">
          <TextField sx={{ m: 2 }}
            id="outlined-basic" 
            label="URL" 
            variant="outlined"
            value={props.url}
            onChange={e => props.setUrl(e.target.value)}/>
          <TextField sx={{ m: 2 }}
            id="outlined-basic" 
            label="Headers" 
            variant="outlined"
            value={props.headers}
            onChange={e => props.setHeaders(e.target.value)}/>
          <Button 
          sx={{ m: 2 }} 
          variant="contained" 
          component="label" 
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={_ => props.handleAddWebhook()}>
          Adicionar
          </Button>
        </Stack>
    </Stack>
  )
}

export default function IntegrationPage() {

  const [isVisible, setIsVisible] = useState(false)
  const [addWebhook, setAddWebhook] = useState(false)
  const [webhookList, setWebhookList] = useState([])

  const [url, setUrl] = useState('')
  const [headers, setHeaders] = useState('')

  const { entity, getWebhooks, createWebhook, deleteWebhook } = useContext(EntityContext)

  const navigate = useNavigate();

  const handleNavigateToPlans = () => {
      navigate('/dashboard/plan/management');
  };
  

  useEffect(() => {
    getConfigurationsList()
    }, [])

    const getConfigurationsList = useCallback(async () => {
	
      getWebhooks()
          .then((data) => {
            
            setWebhookList(data.configurations)
          })
      
        }, [])

  const handleIsVisible = () => {
    setIsVisible(!isVisible)
  }

  const handleAddWebhook = () => {
    createWebhook(url, headers).then(
      (res) => {
        setAddWebhook(!addWebhook)
        setUrl('')
        setHeaders('')
        getConfigurationsList()
      }
    )
  }

  const handleDeleteWebhook = (webhookConfigurationKey) => {
    deleteWebhook(webhookConfigurationKey).then(
      (res) => {
        getConfigurationsList()
      }
    )
  }

  const handleShowAddWebhook = () => {
    setAddWebhook(!addWebhook)
  }

  return (
    <>
      <Helmet>
        <title> Configuração </title>
      </Helmet>

      <Container >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Configuração
          </Typography>
        </Stack>

        <Card >
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.2} sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              Informações
            </Typography>
          </Stack>
          <Stack spacing={2} sx={{ p: 3 }}>
            <Typography variant="subtitle2" noWrap>
              Nome: {entity.entity_name}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              Numero de documento: {entity.entity_document_number}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              KEY: {entity.entity_key}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.2} >
            <Typography variant="subtitle2" noWrap>
              Plano: <Label>{entity.plan.name}</Label>
            </Typography>
            <Button onClick={handleNavigateToPlans}>Upgrade/Downgrade</Button>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.2}  sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              Integrações
            </Typography>
          </Stack>
          { entity.plan.api_allowed ?
          
            <Stack spacing={2} sx={{ p: 3 }}  direction="row" justifyContent="space-between">
              <Typography variant="subtitle2" noWrap>
                API KEY:   { isVisible ? entity.api_key : "********************"}
              </Typography>
              <Stack direction="row" spacing={2}>
              <IconButton size="small" color="inherit" onClick={_ => handleIsVisible()}>
                { isVisible ? <VisibilityOffIcon /> : <VisibilityIcon /> }
              </IconButton>
              </Stack>
            </Stack>

            :

            <Stack spacing={2} sx={{ p: 3 }}  direction="row" justifyContent="space-between">
              <Typography variant="subtitle2" noWrap>
                Este Plano Não Contempla o Uso de APIs.
              </Typography>
            </Stack>
        
          }

          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.2}  sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              Webhooks
            </Typography>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={_ => handleShowAddWebhook()}>
              Novo Webhook
            </Button>
          </Stack>

          { addWebhook ? < AddWebhookCard url={url} headers={headers} setHeaders={setHeaders} setUrl={setUrl} handleAddWebhook={handleAddWebhook}/> : <></> }

          <Stack spacing={2} sx={{ p: 3 }}  direction="column" justifyContent="space-between">
          <WebhookList webhookList={webhookList} handleDeleteWebhook={handleDeleteWebhook}/>
          </Stack>
        </Card>
      </Container>
    </>
  );
}
